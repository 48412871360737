/* SERVICES INDEX */

#hero-services {
  padding-bottom: 100px;
  padding-top: 25px;
}

#hero-services h1 a {
  transition: 0.3s ease-in-out;
  -webkit-text-stroke: 3px transparent;
}

#hero-services h1 a:hover {
  -webkit-text-stroke: 3px #0f1b30;
  transition: 0.3s ease-in-out;
}

#hero-services .jeff {
  font-size: 45px;
}

#hero-services .jeff span {
  position: relative;
  background: -webkit-linear-gradient(45deg, #4cb8b6, #0099d6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#hero-services .jeff span svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
}

#hero-services .jeff span svg path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s 3s linear 1 forwards;
}

#all-services {
  padding: 0 75px;
  margin-bottom: 75px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 100px;
}

#all-services img {
  max-width: 100%;
  height: auto;
}

#all-services img,
#all-services a {
  border-radius: 12px;
}

#all-services .row-1 {
  display: flex;
  justify-content: center;
  gap: 100px;
}

#all-services .row-2 {
  display: flex;
  justify-content: center;
}

#all-services .row-3 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10%;
  padding-right: 10%;
}

#all-services .row-3 > div:last-of-type {
  z-index: 2;
}

#all-services .row-4 {
  display: flex;
  justify-content: flex-end;
  padding-right: 20%;
}

#all-services .row-5 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 100px;
}

#all-services .row-6 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

#all-services .row-7 {
  display: flex;
  justify-content: center;
  align-items: center;
}

#hero-services h1 {
  margin: 20px 0;
}

#graphic-design {
  cursor: url("./ServicesPageImages/graphic-design.svg") 56.5 56.5, auto;
}

#branding {
  cursor: url("./ServicesPageImages/icon-branding.svg") 56.5 56.5, auto;
}

#photography {
  cursor: url("./ServicesPageImages/icon-photography.svg") 56.5 56.5, auto;
}

#packaging {
  cursor: url("./ServicesPageImages/icon-packaging.svg") 56.5 56.5, auto;
}

#web-design {
  cursor: url("./ServicesPageImages/icon-web-design.svg") 56.5 56.5, auto;
}

#ux-design {
  cursor: url("./ServicesPageImages/icon-ux.svg") 56.5 56.5, auto;
}

#print-design {
  cursor: url("./ServicesPageImages/icon-print-design.svg") 56.5 56.5, auto;
}

#social-media {
  cursor: url("./ServicesPageImages/icon-social-media.svg") 56.5 56.5, auto;
}

#image-editing {
  cursor: url("./ServicesPageImages/icon-img-editing.svg") 56.5 56.5, auto;
}

#ecommerce {
  cursor: url("./ServicesPageImages/icon-ecommerce.svg") 56.5 56.5, auto;
}

#all-services.touch a {
  position: relative;
  display: flex;
}

#all-services.touch a::after {
  mix-blend-mode: hard-light;
  text-shadow: 0 0 20px rgba(0, 0, 0, 1);
  content: attr(text);
  position: absolute;
  top: 0;
  left: 0;
  right: 0%;
  bottom: 0%;
  padding: 50px;
  border-radius: 12px;
  /* background: -moz-linear-gradient(
    180deg,
    rgba(15, 27, 48, 0) 0%,
    rgba(15, 27, 48, 0.9) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(15, 27, 48, 0) 0%,
    rgba(15, 27, 48, 0.9) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(15, 27, 48, 0) 0%,
    rgba(15, 27, 48, 0.9) 100%
  ); */
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 2rem;
  line-height: 1.1;
}

#all-services.touch a#photography::after {
  justify-content: flex-start;
  align-items: flex-start;
}

#all-services.touch a#ux-design::after {
  justify-content: flex-start;
}

#all-services.touch a#print-design::after {
  align-items: flex-start;
}

/* SERVICES INNER */

.aim-hyphen .p-lg p {
  font-size: 1.4rem;
}

.serviceshero {
  margin-bottom: 100px;
  margin-top: 50px;
}

.services-hero-sub-heading {
  margin-bottom: 50px;
  font-weight: 500;
}

.services-hero-text {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.services-hero-text .text {
  width: 55%;
}

.services-hero-points {
  max-width: 315px;
}

.services-hero-points .stats .stat:not(:last-of-type) {
  margin-bottom: 30px;
}

.services-hero-points .stats .stat {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.services-hero-points .stats .stat > .icon {
  margin-right: 20px;
  background-image: url("../AboutPage/AboutPageImages/stat-template.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 18px;
  padding-bottom: 10px;
  font-size: 2rem;
  line-height: 1;
}

.services-hero-points .stats .stat p.figure {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 36px;
  line-height: 1;
  margin-top: 0;
}

.services-hero-points .stats .stat p {
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.2;
}

.lprojects {
  margin-bottom: 50px;
}

.heavy {
  font-weight: 500;
}

.blue {
  background: -webkit-linear-gradient(45deg, #4cb8b6, #0099d6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.services-hero-text h1 {
  font-weight: 400;
}

.services-hero-text h1 .blue {
  position: relative;
}

.services-hero-text h1 .blue svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 110%;
}

.services-hero-text h1 .blue svg path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 2s 2s linear 1 forwards;
}

.services-hero-text .text > p {
  font-weight: 400;
}

#services-hero-images {
  margin-bottom: 100px;
}

.services-hero-images {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
}

.services-hero-images .image.two {
  position: relative;
  margin-top: 75px;
}

.services-hero-images .text {
  text-align: left;
  color: white;
  position: absolute;
  top: 75px;
  right: 75px;
  left: 75px;
}

.services-hero-images .text p:last-of-type {
  font-weight: 500;
}

.services-hero-images .text p {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.services-hero-images img {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.our-aim-img {
  /* margin-left: 50px; */
  max-width: 45%;
  height: auto;
}

.our-aim {
  position: relative;
}

.aim-hyphen span {
  position: relative;
  font-size: 25px;
  margin-right: 10px;
}

.aim-hyphen svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 140%;
}

.aim-hyphen p {
  margin-bottom: 50px;
}

.two-two {
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr;
}

.cta-drops.full {
  width: 100%;
}

.mission-text .awards {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.cta-drops .each-accordion {
  width: 100%;
}

.cta-drops .accordion-toggle {
  width: 100%;
  padding: 20px 0;
  cursor: pointer;
  border-radius: 0;
  background: transparent;
  border: none;
  font-weight: 400;
  text-align: left;
  border-bottom: 2px solid #ded4d4;
  position: relative;
}

.cta-drops .accordion-toggle span {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-drops .each-accordion.open .accordion-toggle span.close {
  opacity: 100%;
}

.cta-drops .each-accordion .accordion-toggle span.close {
  opacity: 0%;
}

.cta-drops .each-accordion .accordion-toggle span.open {
  opacity: 100%;
}

.cta-drops .each-accordion.open .accordion-toggle span.open {
  opacity: 0%;
}

.cta-drops .accordion-container {
  width: 100%;
  height: 0px;
  overflow: hidden;
  transition: 0.5s;
}

.cta-drops .accordion-text {
  padding: 20px 0;
}

.cta-drops .accordion-text p:last-of-type {
  margin-bottom: 0;
}

.our-aim .cta-drops {
  width: 100% !important;
  margin-bottom: 30px;
}

.accordion-text .underline-link {
  margin-top: 20px;
  margin-bottom: 20px;
}

.lprojects span {
  font-weight: 500;
}

.made-mission {
  margin-bottom: 38vh;
  margin-top: 25vh;
}

.made-mission .two-two {
  grid-template-columns: 60% 40%;
}

.full-img-service img {
  max-width: 100%;
}

#services-split-text {
  margin-bottom: 100px;
}

#services-split-text .two-two {
  position: relative;
}

#services-split-text .other-serv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

#services-split-text .other-serv {
  position: absolute;
  bottom: 100%;
  right: 0;
}

#services-split-text .other-serv p {
  font-size: 2.5rem;
  margin-left: 20px;
}

@media screen and (max-width: 1900px) {
  .services-hero-images {
    gap: 15px;
  }
}

@media screen and (max-width: 1400px) {
  .services-hero-text .text {
    width: 65%;
  }
}

@media screen and (max-width: 1200px) {
  .services-hero-text .text {
    width: 70%;
  }
  .our-aim {
    padding-bottom: 0;
  }
  .mission-text div {
    width: 100%;
  }
  #all-services .row-1,
  #all-services {
    gap: 50px;
  }
  #all-services .row-4 {
    padding-right: 0;
    padding-left: 50px;
    justify-content: flex-start;
  }
  #all-services .row-5 > div:first-of-type,
  #all-services .row-3 > div:first-of-type {
    z-index: 2;
  }
  #all-services .row-5 {
    align-items: center;
  }
  .services-hero-points {
    max-width: 300px;
  }
  .services-hero-text .text {
    width: calc(100% - 350px);
  }
}

@media screen and (max-width: 1024px) {
  #all-services .row-4 {
    padding-left: 100px;
    padding-right: 25px;
  }
  #all-services .row-5 {
    margin-bottom: 50px;
  }
  .made-mission .two-two {
    grid-template-columns: 75% 25%;
  }
}

@media screen and (max-width: 992px) {
  .services-hero-text .text {
    width: 100%;
  }
  .our-aim-img {
    left: 10px;
  }
  #all-services .row-6 > div:first-of-type {
    padding-left: 50px;
  }
  #all-services .row-2 > div:first-of-type {
    padding-left: 150px;
  }
  #all-services.touch a::after {
    font-size: 1.5rem;
    padding: 25px;
  }
  .services-hero-text {
    display: block;
  }
  .services-hero-text .text {
    width: 100%;
  }
  .services-hero-points {
    max-width: initial;
    margin-top: 50px;
  }
  .services-hero-points .stats .stat .icon {
    width: fit-content;
    margin-bottom: 1rem;
    margin-right: 0;
  }
  .services-hero-points .stats .stat {
    display: block;
  }
  .services-hero-points .stats .stat:not(:last-of-type) {
    margin-bottom: 0;
  }
  .services-hero-points .stats {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 50px;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 767px) {
  .aim-hyphen .p-lg p {
    font-size: 1.5rem;
  }
  #hero-services .jeff {
    font-size: 25px;
  }
  #all-services {
    padding-left: 0px;
    padding-right: 0px;
  }
  #all-services .row-1,
  #all-services .row-5,
  #all-services .row-3,
  #all-services {
    gap: 15px;
  }
  #all-services .row-1 > div,
  #all-services .row-2 > div,
  #all-services .row-3 > div,
  #all-services .row-4 > div,
  #all-services .row-5 > div,
  #all-services .row-6 > div,
  #all-services .row-7 > div {
    transform: none !important;
  }
  #all-services .row-1,
  #all-services .row-2,
  #all-services .row-3,
  #all-services .row-4,
  #all-services .row-5,
  #all-services .row-6,
  #all-services .row-7 {
    display: block;
  }
  #all-services .row-1 a,
  #all-services .row-2 a,
  #all-services .row-3 a,
  #all-services .row-4 a,
  #all-services .row-5 a,
  #all-services .row-6 a,
  #all-services .row-7 a {
    height: 0;
    padding-bottom: 100%;
    width: 100%;
  }
  #all-services .row-1 img,
  #all-services .row-2 img,
  #all-services .row-3 img,
  #all-services .row-4 img,
  #all-services .row-5 img,
  #all-services .row-6 img,
  #all-services .row-7 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  #all-services .row-1 a::after,
  #all-services .row-2 a::after,
  #all-services .row-3 a::after,
  #all-services .row-4 a::after,
  #all-services .row-5 a::after,
  #all-services .row-6 a::after,
  #all-services .row-7 a::after {
    align-items: flex-end !important;
    justify-content: center !important;
  }
  #all-services .row-5 {
    margin-bottom: 0;
  }
  #all-services .row-6 > div:first-of-type {
    padding-left: 0;
  }
  #all-services {
    gap: 0;
  }
  #all-services img,
  #all-services a {
    border-radius: 0;
  }
  #services-split-text .other-serv {
    flex-direction: row-reverse;
    right: initial;
    left: 0;
  }
  #services-split-text .other-serv p {
    font-size: 25px;
    margin-left: 0;
    margin-right: 20px;
  }
  #services-split-text .other-serv img {
    transform: scaleX(-100%);
  }
  #services-split-text .two-two {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  #services-split-text .two-two .cta-drops.full {
    margin-top: 1rem;
  }
  #all-services .row-2 > div:first-of-type {
    padding-left: 0;
  }
  #all-services .row-3 {
    margin-bottom: 0;
    padding-right: 0;
  }
  #all-services .row-4 {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  #all-services.touch a::after {
    border-radius: 0;
  }
  #hero-services {
    padding-bottom: 50px;
  }
  #all-services {
    margin-bottom: 125px;
  }
  .services-hero-points .stats {
    display: block;
  }
  .services-hero-points .stats .stat:not(:last-of-type) {
    margin-bottom: 30px;
  }
  .services-hero-points .stats .stat {
    display: flex;
  }
  .services-hero-points .stats .stat .icon {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .services-hero-images {
    flex-direction: column;
  }
  .services-hero-images > div {
    display: flex;
  }
  .services-hero-images .image.two {
    margin-top: 0;
  }
  .aim-hyphen p {
    margin-bottom: 1rem;
  }
  .our-aim .two-two,
  .made-mission .two-two {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .our-aim .cta-drops {
    margin-top: 0;
  }
  .our-aim-img {
    margin-top: 50px !important;
    max-width: 100%;
  }
  .quote-content {
    width: 100%;
  }
}
