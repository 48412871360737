#client-feedback {
  margin-bottom: 100px;
}

.client-feedback-title {
  margin-bottom: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.client-feedback-title h2 span {
  font-weight: 400;
}

.client-feedback-title h2 {
  margin-bottom: 0;
}

.client-feedback-title .reviews {
  display: flex;
  align-items: center;
}

.client-feedback-title .reviews a:first-of-type {
  margin-right: 30px;
}

.client-feedback {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.client-feedback-images {
  width: 47.5%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.client-feedback-images img {
  border-radius: 10px;
  width: 15%;
  object-fit: cover;
  transition: 0.5s ease-in-out;
  height: 759px;
}

.client-feedback-images img:not(:first-of-type) {
  margin-left: 20px;
}

.client-feedback-images img.active {
  width: 65%;
}

.client-feedback-images img.hidden {
  width: 0%;
  margin-left: 0;
}

.client-feedback-text {
  width: 47.5%;
  display: grid;
  grid-template-columns: 1fr;
}

.client-feedback-text div {
  grid-row-start: 1;
  grid-column-start: 1;
  opacity: 0%;
  transition: 0.5s ease-in-out;
  width: 70%;
}

.client-feedback-text div.active {
  opacity: 100%;
}

.client-feedback-text .logo {
  margin-bottom: 20px;
  mix-blend-mode: multiply;
}

.client-feedback-text .p-lg,
.client-feedback-text .company {
  font-weight: 400;
}

.client-feedback-text p {
  margin-bottom: 0;
}

.client-feedback-text .p-lg {
  margin-bottom: 40px;
}

.client-feedback-nav {
  display: none;
}

.client-feedback-nav.touch {
  display: flex;
  justify-content: center;
  gap: 0 25px;
  margin-top: 25px;
}

.client-feedback-nav.touch button {
  padding: 0;
  border: none;
  background: none;
}

.client-feedback-nav.touch button.inactive {
  opacity: 0.5;
  pointer-events: none;
}

@media screen and (max-width: 1400px) {
  .client-feedback-images img {
    height: 600px;
  }
}

@media screen and (max-width: 1200px) {
  .client-feedback-images img {
    height: 500px;
  }
  .client-feedback-text div {
    width: calc(100% - 15px);
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  .client-feedback-title {
    display: block;
  }
  .client-feedback-title h2 br {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .client-feedback-title h2 {
    margin-bottom: 1rem;
  }
  .client-feedback {
    display: block;
  }
  .client-feedback-images {
    width: calc(100% - 30px);
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 15px;
  }
  .client-feedback-text {
    width: calc(100% - 30px);
    padding-left: 15px;
    padding-right: 15px;
  }
  .client-feedback-text div {
    width: 100%;
  }
  .client-feedback-images img {
    height: 300px;
  }
  .client-feedback-text .logo {
    margin-bottom: 15px;
  }
  .client-feedback-images img:not(:first-of-type) {
    margin-left: 0;
  }
  .client-feedback-images img {
    width: 0%;
  }
  .client-feedback-images img.active {
    width: 100%;
  }
  .client-feedback-title .reviews img {
    max-height: 28px;
    width: auto;
  }
}
