#HomeHero {
  margin-bottom: 150px;
  margin-top: 100px;
}

#HomeHero .say-no {
  display: none;
}

.home-hero h1 {
  text-align: center;
  font-size: 6rem;
  font-weight: 300;
}

.home-hero h1 .we-care {
  position: relative;
}

.home-hero h1 .we-care span {
  position: absolute;
  left: 50%;
  bottom: 100%;
  font-size: 45px;
}

.home-hero h1 .we-care span svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scaleY(1.2);
  width: 150%;
  pointer-events: none;
}

.home-hero h1 .we-care span svg path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s 1s linear 1 forwards;
}

.home-hero h1 .heart-loop {
  rotate: 20deg;
  margin: 0 10px;
  vertical-align: middle;
}

.home-hero h1 .heart-loop #Path_34250 {
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  animation: dash 3s 2s linear 1 forwards;
}

@keyframes toFullOpacity {
  to {
    opacity: 100%;
  }
}

@keyframes toLarge {
  to {
    transform: scale(1.2);
  }
}

@keyframes toSmall {
  to {
    transform: scale(0.8);
  }
}

.home-hero h1 .heart-loop #Path_34251 {
  animation: toFullOpacity 1s 3.2s ease-in-out forwards;
  opacity: 0%;
}

.home-hero h1 .we-listen {
  position: relative;
}

.home-hero h1 .we-listen > img {
  position: absolute;
  top: 65%;
  left: 5%;
  max-width: 65%;
}

.home-hero h1 .woman,
.home-hero h1 .man {
  margin: 0 20px;
  vertical-align: middle;
}

.home-hero h1 .globe {
  position: relative;
}

.home-hero h1 .globe .we-create {
  font-size: 45px;
  position: relative;
  font-family: jeff-script, sans-serif;
  font-weight: 400;
  font-style: normal;
  position: absolute;
  top: 80%;
  right: 0;
  background: -webkit-linear-gradient(45deg, #4cb8b6, #0099d6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
}

.home-hero h1 .one {
  animation: h1one 2s forwards linear;
  display: inline-block;
  animation-timing-function: cubic-bezier(0, 0, 0.34, 1.77);
}

.globe-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 63px;
  width: 66px;
  /* animation: toFullOpacity 1s 6.5s ease-in-out forwards;
  opacity: 0%; */
  margin-left: 10px;
}

.globe-container .countries-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  height: 96%;
  width: 96%;
  border-radius: 100px;
  overflow: hidden;
  z-index: 2;
}

.globe-container .countries {
  position: absolute;
  animation: axis-spin 5s infinite linear;
}

@keyframes axis-spin {
  from {
    transform: translatex(0px);
  }
  to {
    transform: translatex(-123px);
  }
}

.globe-container .globe {
  position: absolute;
  /* filter: drop-shadow(-2px -2px 8px #4cb8b6) drop-shadow(2px 2px 3px #0099d6); */
}

@keyframes h1one {
  0% {
    opacity: 0%;
    transform: scale(0);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

.home-hero h1 .two {
  margin: 0 30px;
  vertical-align: middle;
}

.home-hero h1 .two #Path_34250 {
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  animation: Path_34250 3s 2s ease-in-out forwards;
}

@keyframes Path_34250 {
  to {
    stroke-dashoffset: 0;
  }
}

.home-hero h1 .two #Path_34251 {
  animation: Path_34251 1s 3.2s ease-in-out forwards;
  opacity: 0%;
}

@keyframes Path_34251 {
  to {
    opacity: 100%;
  }
}

.home-hero h1 .three {
  position: relative;
}

.home-hero h1 .three .main {
  opacity: 0%;
}

@keyframes hide {
  to {
    opacity: 0%;
  }
}

@keyframes show {
  to {
    opacity: 100%;
  }
}

@keyframes color {
  to {
    color: #0f1b30;
  }
}

.home-hero h1 .three .three1 {
  position: absolute;
  left: 0;
  opacity: 0%;
  animation: show 1s 3s forwards ease-in-out, color 1s 4s forwards ease-in-out;
  white-space: nowrap;
  color: #0099d6;
}

.home-hero h1 .three .three1 .hide {
  animation: hide 1s 4s forwards ease-in-out;
}

.home-hero h1 .three .three2 {
  position: absolute;
  left: 60px;
  opacity: 0%;
  animation: show 1s 4s forwards ease-in-out, color 1s 5s forwards ease-in-out;
  white-space: nowrap;
  color: #0099d6;
}

.home-hero h1 .three .three2 .hide {
  animation: hide 1s 5s forwards ease-in-out;
}

.home-hero h1 .three .three3 {
  position: absolute;
  left: 111px;
  opacity: 0%;
  animation: show 1s 5s forwards ease-in-out, color 1s 6s forwards ease-in-out;
  white-space: nowrap;
  color: #0099d6;
}

.home-hero h1 .three .three3 .hide {
  animation: hide 1s 6s forwards ease-in-out;
}

.home-hero h1 .three .three4 {
  position: absolute;
  left: 165px;
  opacity: 0%;
  animation: show 1s 6s forwards ease-in-out, color 1s 7s forwards ease-in-out;
  white-space: nowrap;
  color: #0099d6;
}

.home-hero h1 .three .three4 .hide {
  animation: hide 1s 7s forwards ease-in-out;
}

.home-hero h1 .three .three5 {
  position: absolute;
  left: 225px;
  opacity: 0%;
  animation: show 1s 7s forwards ease-in-out, color 1s 8s forwards ease-in-out;
  white-space: nowrap;
  color: #0099d6;
}

.home-hero h1 .three .three5 .hide {
  animation: hide 1s 8s forwards ease-in-out;
}

.home-hero h1 .three .three6 {
  position: absolute;
  left: 284px;
  opacity: 0%;
  animation: show 1s 8s forwards ease-in-out, color 1s 9s forwards ease-in-out;
  white-space: nowrap;
  color: #0099d6;
}

.home-hero h1 .three .three6 .hide {
  animation: hide 1s 9s forwards ease-in-out;
}

.home-hero {
  text-align: center;
}

.split-text {
  display: grid;
  gap: 75px;
  margin-bottom: 75px;
}

.split-text.third {
  grid-template-columns: 1fr 2fr;
}

.split-text.third .jeff {
  font-size: 35px;
  position: relative;
}

.split-text.third .jeff svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.car {
  max-height: 30px;
}

.passion {
  margin-bottom: -28px;
}

.split-text.third .text-right {
  margin-top: 15px;
}

.split-text-con {
  position: relative;
}

.reviews-imgs {
  position: absolute;
  top: 30px;
  right: 30px;
}

.right-text {
  width: 45%;
  margin-left: auto;
}

.right-text h2 span {
  font-weight: 400;
}

.serv-links div {
  width: 100%;
  margin-bottom: 30px;
}

.serv-links a {
  border-bottom: 2px solid black;
  font-weight: 500;
  color: #0f1b30;
  padding-bottom: 5px;
}

.home-split-gif {
  margin-top: 150px;
  display: flex;
}

#home-final-text {
  margin-top: 100px;
}

.home-final-text {
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .home-hero h1 {
    font-size: 5rem;
  }
  .home-hero h1 .woman,
  .home-hero h1 .man {
    height: 100px;
    width: 100px;
  }
}

@media screen and (max-width: 1112px) {
  .home-hero h1 {
    font-size: 4rem;
  }
  .home-hero h1 .we-care span,
  .home-hero h1 .globe .we-create {
    font-size: 40px;
  }
  .home-hero h1 .we-care span svg {
    transform: translate(-50%, -50%) scaleY(1.1);
    width: 140%;
  }
}

@media screen and (max-width: 992px) {
  .home-hero h1 {
    font-size: 45px;
  }
  .home-hero h1 .we-care span,
  .home-hero h1 .globe .we-create {
    font-size: 30px;
  }
  .home-hero h1 .woman,
  .home-hero h1 .man {
    height: 75px;
    width: 75px;
    margin-top: -10px;
    margin-bottom: -5px;
  }
  .home-hero h1 .heart-loop {
    width: 75px;
    height: auto;
    margin: 0 20px;
  }
  .globe-container {
    margin-left: 5px;
    transform: scale(0.7);
    position: absolute;
    left: 100%;
    top: 0;
  }
  .home-hero h1 .globe .we-create {
    top: 90%;
  }
  .split-text.third {
    grid-template-columns: 1fr 1fr;
    gap: 0 25px;
  }
}

@media screen and (max-width: 767px) {
  .home-hero h1 .we-care span {
    transform: translateX(-50%);
  }
  .right-text {
    width: 100%;
  }
  #HomeHero {
    margin-bottom: 150px;
    margin-top: 0px;
  }
  .split-text.third .text-right {
    margin-top: 0;
  }
  .split-text.third {
    grid-template-columns: 1fr;
  }
  .split-text.third .jeff {
    font-size: 30px;
  }
  .split-text.third .jeff svg {
    transform: translate(-50%, -50%) scaleY(0.9);
  }
  .split-text.third .p-lg img {
    max-height: 20px;
    width: auto;
  }
  .home-hero h1 .woman,
  .home-hero h1 .man {
    margin-left: 10px;
    margin-right: 10px;
  }
  .globe-container,
  .home-hero h1 .heart-loop,
  .home-hero h1 br {
    display: none;
  }
  .home-hero h1 .globe .we-create {
    top: calc(100% + 20px);
    font-size: 1.4rem;
    right: 50%;
    transform: translate(50%);
  }
  .home-hero h1 .we-care span {
    bottom: calc(100% + 20px);
  }
  .home-hero h1 {
    max-width: 380px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 0.5rem;
  }
  #HomeHero .say-no {
    display: block;
    text-align: center;
  }
  .home-hero h1 .we-listen > img {
    left: 30%;
    top: 70%;
  }
  .home-hero h1 .man,
  .home-hero h1 .woman {
    margin-top: 0;
    margin-bottom: 0;
  }
}
/* 
@media screen and (max-width: 582px) {
  .home-hero h1 {
    max-width: 380px;
    margin: 0 auto;
    margin-bottom: 0.5rem;
  }
} */
