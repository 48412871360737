#about-hero {
  margin-top: 50px;
  margin-bottom: 100px;
}

.about-hero {
  display: flex;
}

.about-hero .intro {
  padding-right: 150px;
}

.about-hero .intro h1 span {
  position: relative;
  background: -webkit-linear-gradient(45deg, #4cb8b6, #0099d6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-hero .intro h1 span svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleY(1.3);
  width: 130%;
  object-fit: cover;
}

.about-hero .intro h1 span svg path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s 2s linear 1 forwards;
}

.about-hero .intro p {
  width: 75%;
  margin-left: auto;
}

.about-hero .stats .stat {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.about-hero .stats .stat:not(:last-of-type) {
  margin-bottom: 30px;
}

.about-hero .stats .stat > .icon {
  margin-right: 20px;
  background-image: url("./AboutPageImages/stat-template.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 18px;
  padding-bottom: 10px;
  font-size: 2rem;
  line-height: 1;
}

.about-hero .stats .stat p.figure {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 36px;
  line-height: 1;
  margin-top: 0;
}

.about-hero .stats .stat p {
  font-weight: 400;
  margin-bottom: 0;
}

#about-hero-image {
  text-align: center;
}

#about-hero-image img {
  border-radius: 10px;
  max-width: 95%;
  height: auto;
}

.big-ideas {
  margin: 100px 0;
}

.big-ideas .container {
  display: grid;
  grid-template-columns: 40% 60%;
}

.agency-title div {
  text-align: right;
}

.agency-title div img {
  position: absolute;
  right: 75px;
  top: 75px;
}

.agency-title {
  position: relative;
}

.agency-text .awards {
  display: flex;
  gap: 1rem 50px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.ste-quote {
  text-align: center;
  margin: 25vh 0;
}

.ste-quote p {
  width: 75%;
  margin: 0 auto;
}

.ste-quote p:first-of-type {
  margin-bottom: 20px;
}

.ste-quote img {
  margin-bottom: -10px;
}

.about-services .container {
  display: grid;
  grid-template-columns: 50% 50%;
}

.about-serv-title p {
  width: 75%;
}

.about-serv-title .jeff {
  font-size: 1.4rem;
  color: #4aadc2;
}

.team {
  padding: 100px 0;
}

.team-title {
  width: 65%;
  margin-left: auto;
}

.team-members {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.team-members {
  margin-top: 50px;
}

.cta-drops.about-drops {
  width: 100%;
}

.member-img {
  width: 100%;
  filter: grayscale(100%);
  height: auto;
  border-radius: 15px;
}

.team-members h3 {
  margin-top: 20px;
  margin-bottom: 0;
}

.team-members p {
  font-weight: 500;
}

.about-stats-quote-intro {
  text-align: center;
}

.about-stats-quote-intro span:not(.bold) {
  position: relative;
  background: -webkit-linear-gradient(45deg, #4cb8b6, #0099d6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-stats-quote-intro span svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(120%, 190%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-stats-quote-intro p {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.about-stats-quote-intro > img {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.about-stats-quote {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  margin-top: 50px;
}

.about-stats-quote .stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.about-stats-quote .quote {
  background: #e9ecef;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-mask-image: url("./AboutPageImages/quote-bg-mask.webp");
  mask-image: url("./AboutPageImages/quote-bg-mask.webp");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  padding: 50px;
  padding-top: 100px;
  width: 350px;
  margin: 0 auto;
  margin-top: -200px;
  height: fit-content;
}

.about-stats-quote .quote p {
  text-align: left;
  font-weight: 300;
}

.about-stats-quote .quote .carousel .thumb.selected,
.about-stats-quote .quote .carousel .thumb:hover,
.about-stats-quote .quote .carousel .thumb {
  border: none;
  width: auto !important;
}

.about-stats-quote .quote .carousel .thumbs {
  padding: 0;
  text-align: left;
}

.about-stats-quote .quote .carousel .thumbs-wrapper {
  margin: 0;
}

.about-stats-quote .quote p:nth-last-child(2) {
  margin-bottom: 1rem;
}

.about-stats-quote .quote .dot {
  background: #f8f9fa;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  display: block;
  cursor: pointer;
}

.about-stats-quote .quote .carousel .thumb.selected .dot {
  background: #0f1b30;
}

.about-stats-quote .quote .single .author {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1.2;
  font-size: 1.1rem;
}

.about-stats-quote .quote .single .author img {
  width: auto;
  height: auto;
  margin-right: 10px;
}

#about-final-text {
  margin-top: 100px;
  margin-bottom: 100px;
}

.about-final-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
}

@media screen and (max-width: 1200px) {
  .about-hero .intro {
    padding-right: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .about-hero .intro {
    padding-right: 50px;
  }
  .about-hero .intro p {
    width: 90%;
    margin-left: auto;
    margin-top: 30px;
  }
  .about-serv-title p {
    width: 90%;
  }
  .about-stats-quote .quote {
    height: fit-content;
    width: auto;
    margin-top: -150px;
    margin-right: 20px;
    padding: 30px;
    padding-top: 75px;
  }
  .about-stats-quote {
    gap: 50px;
  }
}

@media screen and (max-width: 992px) {
  .about-hero .intro p {
    width: 100%;
  }
  .agency-title h2 br {
    display: none;
  }
  .agency-title div img {
    max-width: 100%;
    top: 100px;
  }
  .cta-drops.about-drops {
    margin-top: 0;
  }
  .team-title {
    width: 100%;
  }
  .about-hero .stats .stat .icon {
    width: fit-content;
    margin-bottom: 1rem;
    margin-right: 0;
  }
  .about-hero .stats {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 50px;
    margin-top: 50px;
  }
  .about-hero .stats .stat {
    display: block;
  }
  .about-hero .stats .stat:not(:last-of-type) {
    margin-bottom: 0;
  }
  .about-final-text {
    gap: 50px;
  }
  .about-hero {
    display: block;
  }
  .about-hero .intro {
    padding-right: 0;
  }
  .big-ideas .container,
  .about-services .container {
    grid-template-columns: 100%;
  }
  .agency-title div img {
    display: none;
  }
  .team-members {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
  }
  .big-ideas {
    margin: 50px 0;
  }
}

@media screen and (max-width: 768px) {
  .about-stats-quote {
    grid-template-columns: 1fr;
    margin-top: 0;
  }
  .about-stats-quote .quote {
    order: -1;
    margin-right: 0;
    width: 70%;
    margin: 0 auto;
    margin-top: -50px;
  }
}

@media screen and (max-width: 767px) {
  .about-stats-quote-intro p,
  .ste-quote p {
    width: 100%;
  }
  .about-hero .stats {
    display: block;
  }
  .about-hero .stats .stat:not(:last-of-type) {
    margin-bottom: 30px;
  }
  .about-hero .stats .stat {
    display: flex;
  }
  .about-hero .stats .stat .icon {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .about-stats-quote .quote {
    width: 100%;
    -webkit-mask-image: none;
    mask-image: none;
    padding: 25px 0;
    margin-top: 25px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-stats-quote .quote .carousel-root {
    width: calc(100% - 50px);
  }
  .about-stats-quote-intro > img {
    height: 300px;
    object-fit: cover;
  }
  .about-final-text {
    grid-template-columns: 1fr;
  }
  #about-final-text {
    margin-top: 50px;
  }
  #about-hero {
    margin-top: 0;
  }
  .about-hero .intro h1 span {
    margin: 0;
  }
  .about-serv-text > img {
    max-width: 100%;
  }
}
