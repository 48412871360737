#home-side-scroll {
  height: 100vh;
  position: relative;
  overflow-x: scroll;
  pointer-events: none;
}

#home-side-scroll .p-lg {
  line-height: 1.1;
}

.home-side-scroll-overflow {
  display: flex;
  width: fit-content;
  height: 100vh;
}

#home-side-scroll::-webkit-scrollbar,
.home-side-scroll-overflow::-webkit-scrollbar {
  width: 0;
  height: 0;
}

#home-side-scroll .first {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

#home-side-scroll .first h2 {
  font-size: 86px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 30px;
  margin-left: -100px;
}

#home-side-scroll .first h2 span {
  background: -webkit-linear-gradient(45deg, #4cb8b6, #0099d6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

#home-side-scroll .first h2 span .ideas {
  position: absolute;
  bottom: 100%;
  left: 0;
  opacity: 0%;
  mix-blend-mode: multiply;
  transition: 0.2s ease-in-out;
}

#home-side-scroll .first .text {
  width: 475px;
  float: right;
  transform: translateX(90%);
  position: relative;
}

#home-side-scroll .first .text img {
  position: absolute;
  right: 0;
  top: calc(100% + 30px);
}

#home-side-scroll .one {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 50px;
  padding-left: 100px;
}

#home-side-scroll .one > img {
  transform: translateX(100px);
  margin-bottom: 30px;
}

#home-side-scroll .two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#home-side-scroll .two > img {
  max-height: 90vh;
}

#home-side-scroll .three {
  display: flex;
  margin-top: 20vh;
  width: 450px;
  margin-left: -150px;
}

#home-side-scroll .four {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 450px;
}

#home-side-scroll .five {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

#home-side-scroll .five .projects {
  position: absolute;
  bottom: 0;
  left: -250px;
}

#home-side-scroll .six {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 450px;
  margin-left: 50px;
}

#home-side-scroll .seven {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 30px;
}

#home-side-scroll .eight {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 450px;
  position: relative;
}

#home-side-scroll .eight img {
  position: absolute;
  left: 0;
  bottom: 100px;
}

#home-side-scroll .nine {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 100px;
}

#home-side-scroll .nine .jeff {
  position: relative;
  font-size: 50px;
}

#home-side-scroll .nine svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150%;
}

#home-side-scroll .nine h2 {
  font-size: 86px;
  font-weight: 400;
  line-height: 0.9;
  margin-bottom: 30px;
  margin-top: 30px;
}

#home-side-scroll .nine h2 span {
  background: -webkit-linear-gradient(45deg, #4cb8b6, #0099d6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#home-side-scroll .nine a {
  pointer-events: all;
}

#home-side-scroll.touch {
  height: auto;
  overflow: hidden;
  pointer-events: all;
  margin-top: 100px;
  padding-left: 50px;
  padding-right: 50px;
}

#home-side-scroll.touch .home-side-scroll-overflow {
  display: block;
  width: auto;
  height: auto;
}

#home-side-scroll.touch .first {
  display: block;
  width: 100%;
}

#home-side-scroll.touch .first h2 {
  margin-left: 0;
}

#home-side-scroll.touch .first .text {
  transform: none;
}

#home-side-scroll.touch .first .text img {
  top: 100%;
}

#home-side-scroll.touch .one > img {
  display: none;
}

#home-side-scroll.touch .one {
  display: block;
  width: 100%;
  margin-right: 0;
  padding-left: 0;
  float: left;
}

#home-side-scroll.touch .one h2,
#home-side-scroll.touch .one p {
  width: 50%;
}

#home-side-scroll.touch .two {
  display: block;
  float: right;
  margin-top: -100px;
  width: 50%;
}

#home-side-scroll.touch .two > img {
  width: 100%;
  height: auto;
}

#home-side-scroll.touch .three {
  float: left;
  margin-left: 0;
  margin-top: 0px;
  width: 50%;
}

#home-side-scroll.touch .four {
  float: left;
  margin-top: 100px;
  width: 50%;
}

#home-side-scroll.touch .five {
  display: none;
}

#home-side-scroll.touch .six {
  float: left;
  margin-top: 100px;
  width: 50%;
  margin-left: 0;
}

#home-side-scroll.touch .seven {
  display: none;
}

#home-side-scroll.touch .eight {
  float: left;
  margin-top: 100px;
  width: 50%;
}

#home-side-scroll.touch .eight {
  margin-top: 100px;
  width: 100%;
  display: flex;
}

#home-side-scroll.touch .eight div {
  width: 50%;
}

#home-side-scroll.touch .eight img {
  right: 100px;
}

#home-side-scroll.touch .nine {
  width: 100%;
  padding-right: 0;
  float: left;
  display: block;
  margin-top: 100px;
  text-align: center;
}

@media screen and (max-width: 1900px) {
  #home-side-scroll .first .text {
    transform: translateX(150px);
  }
  #home-side-scroll .two > img {
    width: auto;
  }
  #home-side-scroll .three {
    margin-left: -100px;
  }
  #home-side-scroll .five .projects {
    max-height: 50%;
    width: auto;
  }
  #home-side-scroll .seven img {
    max-height: 90%;
    width: auto;
  }
  #home-side-scroll .eight img {
    left: initial;
    right: 0;
    bottom: 15px;
  }
}

@media screen and (max-width: 1024px) {
  #home-side-scroll.touch .two {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1024px) {
  #home-side-scroll.touch .first h2 {
    font-size: 60px;
  }
  #home-side-scroll .first .text {
    width: 50%;
  }
  #home-side-scroll.touch .two {
    margin-top: -50px;
  }
}

@media screen and (max-width: 992px) {
  #home-side-scroll.touch .two {
    width: 80%;
    float: right;
  }
  #home-side-scroll.touch .three {
    width: 100%;
    margin-top: 25px;
  }
  #home-side-scroll.touch .four {
    margin-top: 50px;
    padding-right: 25px;
    width: calc(50% - 25px);
  }
  #home-side-scroll.touch .six {
    margin-top: 50px;
    padding-left: 25px;
    width: calc(50% - 25px);
  }
  #home-side-scroll.touch .eight {
    margin-top: 50px;
  }
  #home-side-scroll.touch .eight div {
    width: calc(50% - 25px);
  }
}

@media screen and (max-width: 767px) {
  #home-side-scroll.touch {
    padding-left: 15px;
    padding-right: 15px;
  }
  #home-side-scroll.touch .first h2 {
    font-size: 35px;
  }
  #home-side-scroll .first .text {
    width: 100%;
  }
  #home-side-scroll.touch .first .text img {
    max-width: 100%;
    height: auto;
    position: initial;
  }
  #home-side-scroll.touch .one {
    margin-top: 50px;
  }
  #home-side-scroll.touch .one h2,
  #home-side-scroll.touch .one p {
    width: 100%;
  }
  #home-side-scroll.touch .two {
    width: 100%;
    margin-top: 0;
  }
  #home-side-scroll.touch .four {
    width: 100%;
    padding-right: 0;
  }
  #home-side-scroll.touch .six {
    width: 100%;
    padding-left: 0;
  }
  #home-side-scroll.touch .eight div {
    width: 100%;
  }
  #home-side-scroll.touch .eight img {
    display: none;
  }
  #home-side-scroll.touch .nine .jeff {
    font-size: 35px;
  }
  #home-side-scroll.touch .nine h2 {
    font-size: 40px;
  }
  #home-side-scroll.touch .nine {
    margin-top: 50px;
  }
}
