.client-boxes {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.client-boxes.half {
  width: 50%;
}

.client-boxes.block {
  display: block;
}

.client-box.large {
  width: 50%;
  padding-bottom: 50%;
}

.client-box.small {
  width: 25%;
  padding-bottom: 25%;
}

.client-boxes.half .client-box.small {
  width: 50%;
  padding-bottom: 50%;
}

.client-boxes.half .client-box.full {
  width: 100%;
  padding-bottom: 100%;
}

.client-boxes.half .client-box.landscape {
  width: 100%;
  padding-bottom: 50%;
}

.client-boxes .client-box.landscape {
  width: 50%;
  padding-bottom: 25%;
}

.client-boxes.half .client-box.portrait {
  width: 50%;
  padding-bottom: 100%;
}

.client-box {
  height: 0;
  position: relative;
}

.client-box .content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.client-box .content > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: 0.3s ease-in-out;
}

.client-box .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 50px;
  background: rgba(15, 27, 48, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  opacity: 0%;
  pointer-events: none;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.client-box .content.open .overlay {
  opacity: 100%;
}

.client-box .content.open > img {
  transform: scale(1.2);
}

.client-box .overlay p {
  color: white;
  margin-bottom: 0;
}

.client-box .overlay .client {
  font-size: 2rem;
  line-height: 1;
}

.client-box .overlay .description {
  font-size: 0.8rem;
}

.client-box .overlay .open-icon {
  position: absolute;
  top: 50px;
  right: 50px;
}

@media screen and (max-width: 1200px) {
  .client-box .overlay {
    padding: 25px;
  }
  .client-box .overlay .open-icon {
    top: 25px;
    right: 25px;
  }
}

@media screen and (max-width: 1200px) {
  .client-box .overlay .client {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 992px) {
  .client-box.large {
    padding-bottom: 100%;
    width: 100%;
  }
  .client-boxes.half {
    width: 100%;
  }
  .client-boxes.half.block {
    width: 50%;
  }
  .client-boxes .client-box.landscape {
    width: 100%;
    padding-bottom: 50%;
    order: 3;
  }
  .client-box.small {
    width: 50%;
    padding-bottom: 50%;
  }
}

@media screen and (max-width: 767px) {
  .client-box .overlay .client {
    font-size: 1.2rem;
  }
  .client-box .overlay .description {
    line-height: 1.2;
  }
}
