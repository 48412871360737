#proudly-trusted-by {
  margin-top: 50px;
  margin-bottom: 50px;
}

.proudly-trusted-by {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  width: 100%;
}

.proudly-trusted-by p {
  margin: 0;
  margin-right: 100px;
  white-space: nowrap;
}

.proudly-trusted-by .brands {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  animation: smoothScrollBrands 60s infinite linear;
}

.proudly-trusted-by .brands.overflow {
  animation: smoothScrollBrands 60s infinite linear;
}

.proudly-trusted-by .brands a {
  margin-right: 100px;
}

.image-overflow {
  overflow: hidden;
  display: flex;
  position: relative;
}

.image-overflow::before {
  content: "";
  background: transparent;
  background: -moz-linear-gradient(
    90deg,
    rgba(248, 249, 250, 1) 0%,
    rgba(248, 249, 250, 0) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(248, 249, 250, 1) 0%,
    rgba(248, 249, 250, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(248, 249, 250, 1) 0%,
    rgba(248, 249, 250, 0) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 7.5%;
  z-index: 2;
  pointer-events: none;
}

.image-overflow::after {
  content: "";
  background: transparent;
  background: -moz-linear-gradient(
    -90deg,
    rgba(248, 249, 250, 1) 0%,
    rgba(248, 249, 250, 0) 100%
  );
  background: -webkit-linear-gradient(
    -90deg,
    rgba(248, 249, 250, 1) 0%,
    rgba(248, 249, 250, 0) 100%
  );
  background: linear-gradient(
    -90deg,
    rgba(248, 249, 250, 1) 0%,
    rgba(248, 249, 250, 0) 100%
  );
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 7.5%;
  z-index: 2;
  pointer-events: none;
}

@keyframes smoothScrollBrands {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 992px) {
  .proudly-trusted-by {
    display: block;
  }
  .proudly-trusted-by p {
    white-space: normal;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
  }
  .proudly-trusted-by .brands a {
    margin-right: 75px;
  }
}

@media screen and (max-width: 767px) {
  .proudly-trusted-by .brands a {
    margin-right: 50px;
  }
}
