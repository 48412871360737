.contact-hero {
  text-align: right;
}

.parking a {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
}

.contact-hero h1 {
  text-align: left;
}

.contact-hero .awards {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: flex-start;
  float: right;
}

#contact-content {
  margin-top: 100px;
  margin-bottom: 100px;
}

.contact-content {
  display: flex;
}

.contact-offices {
  padding-right: 75px;
}

.contact-offices .office {
  padding: 50px;
  border-radius: 10px;
  background: rgba(233, 236, 239, 0.4);
  white-space: nowrap;
}

.contact-offices .office:not(:last-of-type) {
  margin-bottom: 1rem;
}

.contact-offices .office a {
  display: block;
}

.contact-form {
  width: 100%;
}

.contact-form .intro {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  width: fit-content;
  margin-bottom: 50px;
}

.contact-form .intro p {
  white-space: nowrap;
  margin-bottom: 0;
}

.contact-form .intro .gif {
  position: absolute;
  left: calc(100% + 50px);
  bottom: 0;
  opacity: 0%;
  transition: 0.2s ease-in-out;
  pointer-events: none;
}

.contact-form .intro .gif.playing {
  opacity: 100%;
}

.contact-form .intro .we-got-you {
  position: absolute;
  left: calc(100% - 50px);
  top: calc(100% + 20px);
  mix-blend-mode: multiply;
}

.contact-form form > p {
  padding-top: 1rem;
  border-top: 2px solid #e9ecef;
}

.form input:not(input[type="radio"]),
.form textarea {
  display: block;
  font-size: 1.1rem;
  font-family: "roc-grotesk", sans-serif;
  font-weight: 300;
  font-style: normal;
  padding: 16px;
  width: calc(100% - 34px);
  background: #f0f3f5;
  border-radius: 10px;
  border: 1px solid #cad5e2;
  color: #0f1b30;
}

.form input[type="radio"] {
  color: #0f1b30;
}

.form select {
  display: block;
  font-size: 1.1rem;
  font-family: "roc-grotesk", sans-serif;
  font-weight: 300;
  font-style: normal;
  padding: 16px;
  width: 100%;
  background-color: #f0f3f5;
  border-radius: 10px;
  border: 1px solid #cad5e2;
  color: #0f1b30;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("./ContactPageImages/arrow-down-01.svg");
  background-position: center right 4px;
  background-repeat: no-repeat;
  background-size: 8px auto;
}

.form input::placeholder,
.form textarea::placeholder,
.form select::placeholder {
  opacity: 0.4;
  color: #0f1b30;
}

.form-row-conditional {
  display: flex;
  flex-wrap: wrap;
}

.con-hidden {
  height: 0 !important;
  padding-bottom: 0 !important;
  overflow: hidden !important;
}

.form-row-conditional > div:nth-of-type(2n - 1) {
  width: calc(50% - 10px);
  padding-bottom: 20px;
  padding-right: 10px;
}

.form-row-conditional > div:nth-of-type(2n) {
  width: calc(50% - 10px);
  padding-bottom: 20px;
  padding-left: 10px;
}

.form-row-conditional > div input {
  width: calc(50% - 10px);
}

.form-row-split {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.form-row-full div {
  width: 100%;
  padding-bottom: 20px;
}

.form-row-split div {
  width: 50%;
  padding-bottom: 20px;
}

.form-row-split div:first-of-type {
  padding-right: 10px;
}

.form-row-split div:last-of-type {
  padding-left: 10px;
}

.form-row-full.biscuits label:first-of-type {
  margin-right: 20px;
}

.form-sent-status {
  margin-top: 1rem;
}

.form-sent-status.success {
  color: green;
}

.form-sent-status.fail {
  color: red;
}

@media screen and (max-width: 1900px) {
  .contact-form .intro .gif {
    max-width: 60%;
  }
}

@media screen and (max-width: 1440px) {
  .contact-form .intro .gif {
    left: 75%;
    bottom: 100%;
    max-width: 55%;
  }
  .contact-form .intro .we-got-you {
    left: calc(100% - 100px);
  }
  .contact-hero .awards {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: flex-end;
    float: initial;
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .contact-content {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .contact-offices {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-right: 0;
    margin-top: 100px;
  }
  .contact-offices > p {
    width: 100%;
  }
  .contact-offices .office:not(:last-of-type) {
    margin-right: 50px;
    margin-bottom: 0;
  }
  .contact-form .intro .gif {
    position: initial;
  }
  .contact-form .intro {
    gap: 50px;
  }
  #contact-content {
    margin-top: 50px;
  }
  .contact-form .intro .we-got-you {
    left: 50%;
  }
}

@media screen and (max-width: 992px) {
  .contact-form .intro .gif {
    position: absolute;
    bottom: 0;
  }
  .contact-form .intro {
    flex-direction: column-reverse;
  }
  .contact-offices .office:not(:last-of-type) {
    margin-right: 0;
    margin-bottom: 50px;
  }
  .contact-offices .office {
    width: 100%;
  }
  #contact-content {
    margin-top: 50px;
  }
  .contact-form .intro .we-got-you {
    left: 80%;
  }
  .parking a {
    justify-content: start;
  }
  .parking a img {
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .contact-form .intro .we-got-you {
    left: initial;
    right: 0;
  }
  .contact-form .intro .gif {
    left: initial;
    right: 0;
    height: auto;
    max-width: 70%;
  }
  .contact-form .intro {
    width: 100%;
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .contact-hero .awards {
    margin-bottom: 10px;
    justify-content: flex-start;
  }
  .contact-form .intro p {
    white-space: normal;
  }
  .contact-form .intro {
    display: block;
  }
  .contact-hero h1 br {
    display: none;
  }
  .form-row-split div:first-of-type {
    padding-right: 0;
  }
  .form-row-split div:last-of-type {
    padding-left: 0;
  }
  .form-row-split {
    flex-wrap: wrap;
  }
  .form-row-split div {
    width: 100%;
  }
  .contact-form .intro .we-got-you {
    position: initial;
    float: right;
  }
}
