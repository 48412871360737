/* #single-project-hero {
  height: 100vh;
} */

/* #single-project-hero img {
  object-fit: cover;
  height: 100vh;
  width: 100%;
  position: relative;
} */

.main-featured {
  max-width: 50%;
  margin-left: auto;
  text-align: end;
  /* position: relative;
  z-index: 10000; */
}

.main-featured img {
  /* mix-blend-mode: multiply; */
  max-height: calc(100vh - 200px);
  max-width: 100%;
  object-fit: contain;
  display: flex;
}

#single-project-hero {
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: flex-end;
}

#hero-content {
  position: absolute;
  top: 50%;
  width: calc(100% - 100px);
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
}

#pro-intro {
  padding-top: 100px;
  padding-bottom: 100px;
}

/* .single-post-intro#link-intro {
  padding-top: 50px;
  width: 45%;
} */

#single-project-hero .single-post-intro#link-intro {
  width: 45%;
}

.sm-text {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.client-info {
  padding-bottom: 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cecece;
}

#two-img {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 15px;
}

#two-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* mix-blend-mode: multiply; */
}

#fw-img img {
  width: 100%;
}

#one-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pro-logo {
  max-width: 25%;
  mix-blend-mode: multiply;
}

.project-items a {
  cursor: url("../Universal/CollectionPosts/CollectionPostsImages/blog-cursor.webp")
      56.5 56.5,
    auto;
  transition: 0.5s ease-in-out;
}

h1 .blue-circle {
  position: relative;
  background: -webkit-linear-gradient(45deg, #4cb8b6, #0099d6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1 .blue-circle svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleY(1.3);
  width: 130%;
}

h1 .blue-circle svg path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s 3s linear 1 forwards;
}

/* .blue-circle img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 110%;
} */
/* 
#projects-hero h1 {
  font-weight: 400;
} */

.p-categories-options {
  margin-bottom: 50px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.p-categories-options select {
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 30px;
  padding: 15px 40px 15px 20px;
  font-size: 1.2rem;
  font-family: "roc-grotesk", sans-serif;
  font-weight: 400;
  font-style: normal;
  border: 1px solid transparent;
  cursor: pointer;
  width: auto;
  color: #0f1b30;
  background-color: #e9ecef;
  display: inline;
  background-image: url("../ProjectsPage/ProjectPageImages/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: center right 15px;
}

.brief-outcome {
  display: grid;
  grid-template-columns: 40% 60%;
}

.over-half-text {
  display: grid;
  grid-template-columns: 60%;
}

.quote {
  text-align: center;
}

.quote h4 {
  font-weight: 500;
}

.quote p:nth-last-child(2) {
  margin-bottom: 0;
  font-weight: 200;
}

.quote p {
  font-weight: 500;
}

#fw-img {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
}

.touchscreen .slider-oppo-arrows .carousel .control-arrow:before,
.touchscreen
  .slider-oppo-arrows
  .carousel.carousel-slider
  .control-arrow::before {
  display: none !important;
}

.touchscreen .slider-oppo-arrows .control-arrow.control-prev {
  position: absolute;
  right: 55%;
  left: initial;
  top: 100%;
  width: 100px;
  height: 100px;
  transform: translateY(-50%);
  background: url("../Universal/ClientFeedback/ClientFeedbackImages/feedback-cursor.webp") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  bottom: initial;
  padding: 0;
  cursor: pointer;
}

.touchscreen .slider-oppo-arrows .control-arrow.control-next {
  position: absolute;
  left: 55%;
  right: initial;
  top: 100%;
  width: 100px;
  height: 100px;
  transform: translateY(-50%);
  background: url("../Universal/CollectionPosts/CollectionPostsImages/blog-cursor.webp") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  bottom: initial;
  padding: 0;
  cursor: pointer;
}

.carousel.carousel-slider {
  overflow: visible;
}

/* .slider-oppo-arrows .control-arrow.control-next::after {
  display: block;
  content: "";
  width: 75px;
  height: 75px;
  background: url("../ProjectsPage/ProjectPageImages/right-arrow.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
} */

/* .slider-oppo-arrows .control-arrow.control-prev::after {
  display: block;
  content: "";
  width: 75px;
  height: 75px;
  background: url("../ProjectsPage/ProjectPageImages/right-arrow.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(180deg);
} */

.touchscreen .slider-oppo-arrows .carousel .control-arrow,
.touchscreen .slider-oppo-arrows .carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
  display: block;
}

.touchscreen .slider-oppo-arrows .carousel .control-disabled.control-arrow {
  opacity: 0.5 !important;
  pointer-events: none;
}
/* 
.main-intro p {
  padding-right: 50px;
} */

.non-touchscreen .carousel.carousel-slider .control-arrow {
  width: 50%;
  background: transparent !important;
  padding: 0;
  cursor: url("../Universal/CollectionPosts/CollectionPostsImages/blog-cursor.webp")
      56.5 56.5,
    auto;
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
}

.non-touchscreen .carousel.carousel-slider .control-arrow.control-prev {
  cursor: url("../Universal/ClientFeedback/ClientFeedbackImages/feedback-cursor.webp")
      56.5 56.5,
    auto;
}

.non-touchscreen .carousel.carousel-slider .control-arrow.control-next {
  cursor: url("../Universal/CollectionPosts/CollectionPostsImages/blog-cursor.webp")
      56.5 56.5,
    auto;
}

.non-touchscreen
  .carousel
  .control-prev.control-arrow:before
  .non-touchscreen
  .carousel
  .control-next.control-arrow:before {
  display: none;
}

/* SMALL */

#single-project-hero-small {
  position: relative;
  max-height: calc(100vh - 188px);
  display: flex;
}

#single-project-hero-small .hero {
  width: 50%;
  max-height: calc(100vh - 188px);
  display: flex;
}

#single-project-hero-small .hero img {
  width: 100%;
  max-height: calc(100vh - 188px);
  object-fit: cover;
}

.single-project-hero-small {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-project-hero-small .two-one {
  width: 60%;
}

#single-project-images-small {
  position: relative;
  display: flex;
}

#single-project-images-small .images {
  width: 50%;
}

#single-project-images-small .images .hover-image {
  width: 100%;
  position: relative;
  display: grid;
}

#single-project-images-small .images .hover-image.content {
  cursor: help;
}

#single-project-images-small .images .hover-image img {
  width: 100%;
}

#single-project-images-small .images .hover-image.content::after {
  content: "";
  background: rgb(13, 22, 38);
  background: -moz-linear-gradient(
    0deg,
    rgba(13, 22, 38, 0.5956757703081232) 0%,
    rgba(13, 22, 38, 0) 90%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(13, 22, 38, 0.5956757703081232) 0%,
    rgba(13, 22, 38, 0) 90%
  );
  background: linear-gradient(
    0deg,
    rgba(13, 22, 38, 0.5956757703081232) 0%,
    rgba(13, 22, 38, 0) 90%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0%;
  pointer-events: none;
  transition: 0.5s ease-in-out;
  z-index: 2;
}

#single-project-images-small .images .hover-image.content:hover::after {
  opacity: 100%;
}

#single-project-images-small .images .hover-image.content::before {
  content: attr(text);
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 200px);
  height: calc(100% - 200px);
  transform: translate(-50%, -50%);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  opacity: 0%;
  pointer-events: none;
  transition: 0.5s ease-in-out;
  z-index: 3;
  color: white;
}

#single-project-images-small .images .hover-image.content:hover::before {
  opacity: 100%;
}

.single-project-images-small {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
}

.single-project-images-small .brief {
  position: sticky;
  top: 10%;
  height: fit-content;
  width: 60%;
}

.single-project-images-small .brief p:last-of-type {
  margin-bottom: 0;
}

#single-project-small-slider {
  margin: 25px;
}

#single-post-nav {
  overflow: hidden;
  margin: 50px 0 100px;
}

.single-post-nav .next {
  float: right;
  text-align: right;
}

.single-post-nav .next img {
  display: inline;
  margin-left: 10px;
}

.single-post-nav .next p {
  text-align: start;
}

.single-post-nav .prev {
  float: left;
}

.single-post-nav .prev img {
  display: inline;
  margin-right: 10px;
}

.single-post-nav .prev p {
  text-align: end;
}

.single-post-nav p {
  margin-bottom: 0;
  line-height: 1;
  opacity: 0.5;
}

@media screen and (max-width: 1900px) {
  .main-featured img {
    max-height: calc(100vh - 150px);
  }
}

@media screen and (max-width: 1400px) {
  #single-project-hero-small .hero,
  #single-project-hero-small .hero img,
  #single-project-hero-small {
    max-height: initial;
    height: calc(100vh - 149px);
    object-fit: cover;
    object-position: top center;
  }
  #single-project-hero-small .hero img {
    margin-top: -1px;
  }
  .single-project-images-small .brief {
    width: 75%;
  }
}

@media screen and (max-width: 1200px) {
  #single-project-images-small .images .hover-image.content::before {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
  }
  #hero-services h1 a:hover {
    -webkit-text-stroke: 0px #0f1b30;
  }
}

@media screen and (max-width: 992px) {
  #single-project-hero-small .hero,
  #single-project-hero-small .hero img,
  #single-project-hero-small {
    height: auto;
  }
  #single-project-hero {
    min-height: initial;
    display: block;
  }
  #single-project-hero-small {
    display: block;
  }
  #single-project-hero-small .hero,
  .single-project-hero-small {
    width: 100%;
  }
  .single-project-hero-small {
    margin: 100px 0;
  }
  #hero-content {
    position: initial;
    transform: none;
    width: auto;
  }
  #single-project-hero .single-post-intro#link-intro {
    width: 100%;
  }
  .main-featured {
    max-width: 100%;
  }
  .main-featured img {
    max-height: initial;
  }
  .single-post-intro {
    padding-bottom: 50px;
  }
  .pro-logo {
    max-width: 35%;
  }
  header.project.split {
    position: absolute;
    background: transparent !important;
  }
  header.absolute.project.large,
  header.absolute.project {
    position: initial;
  }
}

@media screen and (max-width: 768px) {
  .single-project-images-small .brief {
    width: calc(100% - 30px);
  }
  #single-project-small-slider {
    margin: 15px;
  }
}

@media screen and (max-width: 767px) {
  .project-items {
    grid-template-columns: 1fr;
  }
  #single-project-hero-small {
    display: flex;
    flex-direction: column-reverse;
  }
  header.absolute.project,
  header.project.split {
    position: initial;
  }
  .single-project-hero-small {
    margin-top: 0;
    margin-bottom: 50px;
  }
  #single-project-hero-small {
    margin-bottom: 50px;
  }
  #single-project-images-small {
    flex-direction: column-reverse;
  }
  #single-project-images-small .images,
  .single-project-images-small {
    width: 100%;
  }
  .single-project-images-small {
    margin-bottom: 50px;
  }
  .brief-outcome {
    display: block;
  }
  .single-project-hero-small .two-one {
    width: calc(100% - 30px);
    text-align: center;
  }
  #pro-intro .two-two {
    display: flex;
    flex-direction: column-reverse;
  }
  .single-post-intro {
    padding-top: 0px;
  }
  #pro-intro {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .over-half-text {
    grid-template-columns: 100%;
  }
  .single-post-nav .prev,
  .single-post-nav .next {
    width: fit-content;
    float: none;
  }
  .single-post-nav {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  .pro-logo {
    max-width: 40%;
  }
  .touchscreen .slider-oppo-arrows .control-arrow.control-prev {
    width: 50px;
    height: 50px;
  }
  .touchscreen .slider-oppo-arrows .control-arrow.control-next {
    width: 50px;
    height: 50px;
  }
}
