footer {
  background: #e9ecef;
}

.footer-cta {
  padding: 100px 0;
}

.footer-cta.home {
  margin-top: 100px;
}

.cta-links a {
  color: #0f1b30;
  margin-left: 30px;
  padding-bottom: 10px;
  font-weight: 400;
  border-bottom: 2px solid #0f1b30;
}

.footer-cta h2 {
  font-size: 50px;
  margin: 0;
  line-height: 1;
}

.footer-cta.page h2 {
  margin-bottom: 50px;
}

.callback-div a {
  color: #0f1b30;
  padding-bottom: 10px;
  font-weight: 400;
  border-bottom: 2px solid #0f1b30;
}

.footer-cta.page .crush-call > div a {
  font-weight: 400;
}

.footer-cta.page .crush-call {
  align-items: flex-start;
}

.cta-drops {
  width: 50%;
}

.cta-drops .each-accordion {
  width: 100%;
}

.cta-drops .accordion-toggle {
  width: 100%;
  padding: 20px 0;
  cursor: pointer;
  border-radius: 0;
  background: transparent;
  border: none;
  font-weight: 400;
  text-align: left;
  border-bottom: 2px solid #d2d3d5;
  position: relative;
  color: #0f1b30;
}

.cta-drops .accordion-toggle span {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-drops .each-accordion.open .accordion-toggle span.close {
  opacity: 100%;
}

.cta-drops .each-accordion .accordion-toggle span.close {
  opacity: 0%;
}

.cta-drops .each-accordion .accordion-toggle span.open {
  opacity: 100%;
}

.cta-drops .each-accordion.open .accordion-toggle span.open {
  opacity: 0%;
}

.cta-drops .accordion-container {
  width: 100%;
  height: 0px;
  overflow: hidden;
  transition: 0.5s;
}

.cta-drops .accordion-text {
  padding: 20px 0;
}

.cta-drops .accordion-text p:last-of-type {
  margin-bottom: 0;
}

.main-footer {
  background: #0e1b30;
  padding: 50px 0;
}

.crush-call {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}

.crush-call h2 {
  position: relative;
}

.crush-call h2 img {
  position: absolute;
  left: calc(100% + 30px);
  top: 50%;
  transform: translateY(-50%);
  opacity: 0%;
  transition: 0.2s ease-in-out;
  pointer-events: none;
}

.crush-call h2:hover img {
  opacity: 100%;
}

.footer-nav {
  color: white;
  display: flex;
  width: 50%;
  justify-content: space-between;
  margin-bottom: 75px;
  flex-wrap: wrap;
}

.footer-nav a {
  color: white;
  transition: 0.2s ease-in-out;
  -webkit-text-stroke: 1px transparent;
}

.footer-nav a:hover {
  -webkit-text-stroke: 1px white;
}

.footer-nav ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.footer-nav ul li {
  margin-bottom: 10px;
}

.footer-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-logo {
  height: 30px;
}

footer .shopify {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.smallprint-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
}

.smallprint {
  display: flex;
  align-items: center;
  color: white;
}

.smallprint p {
  margin-bottom: 0;
  margin-right: 20px;
}

.smallprint a {
  color: white;
  margin-right: 20px;
}

.trust-google a:first-of-type,
.trust-google a:nth-child(2) {
  margin-right: 20px;
}

@media screen and (max-width: 1200px) {
  .footer-nav {
    width: 70%;
  }
}

@media screen and (max-width: 992px) {
  .cta-drops {
    width: 100%;
    margin-top: 50px;
  }
  .smallprint-box {
    justify-content: center;
    flex-direction: column-reverse;
  }
  .smallprint-box .smallprint {
    width: 100%;
    justify-content: center;
    margin-top: 30px;
  }
  .footer-flex {
    display: block;
  }
  .footer-nav {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  footer .shopify {
    justify-content: center;
  }
  .crush-call h2 img {
    position: absolute;
    left: 0;
    top: initial;
    bottom: calc(100% + 30px);
    transform: none;
    z-index: 10;
  }
}

@media screen and (max-width: 767px) {
  .bottom-footer .shopify {
    text-align: left;
  }
  .bottom-footer .shopify img {
    max-width: 100%;
  }
  .smallprint {
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  .smallprint p {
    width: 100%;
    margin-bottom: 20px;
  }
  .cta-links a {
    margin-left: 0;
    margin-right: 30px;
  }
  .cta-links {
    margin-top: 30px;
  }
  .footer-cta.page h2 br {
    display: none;
  }
  .footer-nav {
    gap: 50px;
  }
  .footer-nav > ul:first-of-type {
    order: -1;
  }
  .smallprint-box .smallprint {
    text-align: center;
  }
  .smallprint-box .trust-google {
    text-align: center;
  }
}
