header {
  z-index: 10000;
  width: 100%;
  top: 0;
  left: 0;
}

header.absolute {
  position: absolute;
}

header > .top span a {
  display: block;
  margin: 0 auto;
  width: fit-content;
}

header > .top {
  text-align: center;
  position: relative;
  padding: 75px 100px;
}

header > .top .logo {
  margin-left: 5px;
  overflow: visible;
}

.logo-mob {
  display: none;
}

.logo.animate .cls-2,
.logo-mob.animate .cls-1 {
  stroke: #121c30;
  stroke-width: 1px;
  stroke-dasharray: 200;
  fill: transparent;
  stroke-dashoffset: 200;
  /* -webkit-animation: letter 2.5s ease-in-out 0.5s 1 forwards; */
  animation: letter 2.5s ease-in-out 0.5s 1 forwards;
}

.logo.animate.white .cls-2,
.logo-mob.animate.white .cls-1 {
  stroke: #fff;
  stroke-width: 1px;
  fill: transparent;
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  /* -webkit-animation: letter-white 2.5s ease-in-out 0.5s 1 forwards; */
  animation: letter-white 2.5s ease-in-out 0.5s 1 forwards;
}

.logo.animate .letter-p,
.logo-mob.animate.white .letter-p {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.logo.animate .letter-e,
.logo-mob.animate.white .letter-e {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.logo.animate .letter-h2,
.logo.animate.white .letter-h2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.logo.animate .letter-n,
.logo.animate.white .letter-n {
  -webkit-animation-delay: 1.25s;
  animation-delay: 1.25s;
}

.logo.animate .letter-y,
.logo.animate.white .letter-y {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.logo.animate .letter-h,
.logo.animate.white .letter-h {
  -webkit-animation-delay: 1.75s;
  animation-delay: 1.75s;
}

.logo.animate .letter-t,
.logo.animate.white .letter-t {
  opacity: 0%;
  -webkit-animation: letter-opacity 0.5s ease-in-out 4.25s 1 forwards;
  animation: letter-opacity 0.5s ease-in-out 4.25s forwards;
}

.logo.animate .letter-m,
.logo.animate.white .letter-m {
  opacity: 0%;
  -webkit-animation: letter-opacity 0.5s ease-in-out 4.5s 1 forwards;
  animation: letter-opacity 0.5s ease-in-out 4.5s forwards;
}

@keyframes letter {
  0% {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    fill: transparent;
  }
  80% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #121c30;
  }
}

@-webkit-keyframes letter {
  0% {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    fill: transparent;
  }
  80% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #121c30;
  }
}
@keyframes letter-white {
  0% {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    fill: transparent;
  }
  80% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #fff;
  }
}

@-webkit-keyframes letter-white {
  0% {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    fill: transparent;
  }
  80% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #fff;
  }
}

@keyframes letter-opacity {
  to {
    opacity: 100%;
  }
}

@-webkit-keyframes letter-opacity {
  to {
    opacity: 100%;
  }
}

header .top p {
  margin: 0;
}

header .top > p {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100px;
}

header .top > div {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100px;
}

header .top .header-design {
  margin-right: 30px;
  -webkit-text-stroke: 1px transparent;
  transition: 0.2s ease-in-out;
}

header .top .header-design:hover {
  -webkit-text-stroke: 1px #0f1b30;
}

@media screen and (max-width: 1450px) {
  header > .top {
    padding: 50px;
  }
  header .top > p {
    left: 50px;
  }
  header .top > div {
    right: 50px;
  }
}

@media screen and (max-width: 992px) {
  header .top .header-design {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  header > .top {
    padding: 50px 15px;
  }
  header .top > div {
    right: 15px;
  }
  header .top > p {
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
    width: 100%;
    display: none;
  }
  .logo-mob {
    display: block;
    width: 35.51px;
    height: 41.43px;
  }
  .logo {
    display: none;
  }
}
