.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single
  .slider-single-content {
  box-shadow: none !important;
}

.react-3d-carousel {
  cursor: url("./HomePageImages/drag.svg") 13 15, auto;
}

.react-3d-carousel img {
  border-radius: 25px;
  object-fit: cover;
  height: auto;
  aspect-ratio: 1 / 1;
}

.slider-left,
.slider-right {
  display: none !important;
}

.react-3d-carousel {
  margin-bottom: 75px;
}

.carousel-con {
  position: relative;
}

.carousel-con .our-work {
  text-align: right;
  margin-top: -100px;
}

.work-btn {
  text-align: center;
}

@media screen and (max-width: 992px) {
  .carousel-con .our-work {
    margin-top: -140px;
  }
}

@media screen and (max-width: 767px) {
  .carousel-con .our-work img {
    height: 70px;
    width: auto;
  }
}
