.header-menu-toggle {
  height: 14px;
  width: 30px;
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.header-menu-toggle .top {
  width: 30px;
  height: 4px;
  background-color: #0f1b30;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.5s ease-in-out;
}

.header-menu-toggle .bottom {
  width: 20px;
  height: 4px;
  background-color: #0f1b30;
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  transition: 0.5s ease-in-out;
}

.header-menu-toggle.open .top,
.header-menu-toggle.open .bottom {
  width: 0%;
}

.header-menu-toggle.open {
  cursor: default;
  pointer-events: none;
}

.header-menu-toggle.closed .bottom,
.header-menu-toggle.closed .top {
  transition-delay: 0.5s;
}
