#collection-posts h2 span {
  font-weight: 400;
}

#collection-posts h2 {
  margin-bottom: 40px;
}

.collection-posts {
  display: flex;
  /* grid-template-columns: repeat(3, 1fr);
  grid-gap: 0; */
  width: 100%;
}

.blog-post {
  height: 700px;
  padding: 40px;
  display: flex;
  align-items: flex-start;
  color: white;
  flex-direction: column;
  justify-content: flex-end;
  background-blend-mode: overlay;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.blog-post a {
  color: white;
}

.blog-post p {
  margin: 0;
}

.blog-post .text {
  max-width: 475px;
}
/* 
.collection-posts a:nth-child(1) {
  background: black;
}

.collection-posts a:nth-child(2) {
  background: grey;
}

.collection-posts a:nth-child(3) {
  background: blue;
} */

.collection-posts a {
  cursor: url("../CollectionPosts/CollectionPostsImages/blog-cursor.webp") 56.5
      56.5,
    auto;
  width: 33.333333%;
  transition: 0.5s ease-in-out;
}

.collection-posts a:hover {
  width: 50%;
}

@media screen and (max-width: 1900px) {
  .blog-post .text {
    max-width: 300px;
  }
  .blog-post .text h3 {
    font-size: 30px;
  }
}

@media screen and (max-width: 1400px) {
  .blog-post .text {
    max-width: 250px;
  }
  .blog-post {
    height: 600px;
  }
}

@media screen and (max-width: 1200px) {
  .blog-post {
    height: 500px;
  }
}

@media screen and (max-width: 992px) {
  .collection-posts {
    flex-wrap: wrap;
  }
  .collection-posts a,
  .collection-posts a:hover {
    width: 100%;
  }
  .blog-post {
    height: 250px;
    padding: 25px;
  }
  .blog-post .text {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .blog-post {
    height: 450px;
    padding: 25px;
  }
}
