.header-menu {
  width: calc(50vw - 200px);
  height: calc(100vh - 150px);
  position: fixed;
  right: 0;
  top: 0;
  background: #e9ecef;
  padding: 75px 100px;
  transition: 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 10000;
}

.header-menu.open {
  transform: translateX(0%);
  -webkit-box-shadow: -33px 0px 50px 33px rgba(15, 27, 48, 0.2);
  -moz-box-shadow: -33px 0px 50px 33px rgba(15, 27, 48, 0.2);
  box-shadow: -33px 0px 50px 33px rgba(15, 27, 48, 0.2);
  transition-delay: 0.5s;
}

.header-menu.closed {
  transform: translateX(100%);
  -webkit-box-shadow: -0px 0px 50px 0px rgba(15, 27, 48, 0.2);
  -moz-box-shadow: -0px 0px 50px 0px rgba(15, 27, 48, 0.2);
  box-shadow: -0px 0px 50px 0px rgba(15, 27, 48, 0.2);
}

.header-menu__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-menu__top img {
  width: 108.725px;
}

.header-menu__top__close {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 20px;
  width: 20px;
  position: relative;
  border: none;
  background: none;
  padding: 0;
}

.header-menu__top__close .top {
  width: 20px;
  height: 4px;
  background-color: #0f1b30;
  transition: 0.5s ease-in-out;
  position: absolute;
  transform-origin: center;
  transform: rotate(45deg);
}

.header-menu__top__close .bottom {
  width: 20px;
  height: 4px;
  background-color: #0f1b30;
  transition: 0.5s ease-in-out;
  position: absolute;
  transform-origin: center;
  transform: rotate(-45deg);
}

.header-menu__links {
  display: flex;
  flex-direction: column;
  padding-right: 100px;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
  height: 100%;
}

.menu-btn {
  font-size: 3rem;
  text-decoration: none;
  transform-origin: left;
  color: #0f1b30;
}

.menu-btn.active span {
  font-weight: 500;
}

.menu-btn.active {
  pointer-events: none;
  cursor: default;
}

.header-menu.closed .menu-btn {
  transition: 0s ease-in-out !important;
  transition-delay: 0.5s !important;
  transform: scale(0%);
}

.header-menu.open .menu-btn {
  transition: 0.2s ease-in-out;
  transform: scale(100%);
}

.header-menu__bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

@media screen and (max-width: 1900px) {
  .header-menu__bottom {
    flex-direction: column-reverse;
  }
  .header-menu__bottom > div {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1450px) {
  .header-menu {
    width: calc(50vw - 100px);
    height: calc(100vh - 100px);
    padding: 50px;
  }
}

@media screen and (max-height: 850px) {
  .header-menu__bottom {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .header-menu__links {
    padding-right: 0;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 767px) {
  .header-menu {
    width: calc(100% - 30px);
    padding: 50px 15px;
  }
  .header-menu__links {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .header-menu__top img {
    width: initial;
  }
}

@media screen and (max-height: 680px) {
  .header-menu {
    overflow-y: scroll;
  }
  .header-menu__links {
    padding-bottom: 50px;
    padding-top: 50px;
    justify-content: space-between;
    height: auto;
  }
}
