/* @import url("https://use.typekit.net/jtz0evf.css"); */

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

a {
  text-decoration: none;
  color: #0f1b30;
}

body {
  background: #f8f9fa;
}

/* img {
  mix-blend-mode: multiply;
} */

*:focus-visible {
  outline: none;
}

.scroll-into-view {
  transform: translateY(100px);
  opacity: 0%;
  transition: 1s ease-in-out;
}

.scroll-into-view.animating {
  transform: translateY(0px);
  opacity: 100%;
}

body {
  margin: 0;
  font-family: "roc-grotesk", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #0f1b30;
  font-size: 1.2rem;
  line-height: 1.5;
}

button {
  border-radius: 8px;
  padding: 15px 30px;
  font-family: "roc-grotesk", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.2rem;
  transition: 0.3s;
  line-height: 1.2rem;
  font-weight: 400;
  cursor: pointer;
}

button:hover {
  transition: 0.3s;
}

.underline-link {
  border-bottom: 2px solid #0f1b30;
  color: #0f1b30;
  display: inline-block;
}

.blue-btn {
  background-color: #0f1b30;
  color: white;
  border: 2px solid #0f1b30;
}

.blue-btn:hover {
  background-color: transparent;
  color: #0f1b30;
}

.white-trans-btn {
  background-color: white;
  color: #0f1b30;
  border: 2px solid white;
}

.white-trans-btn:hover {
  background-color: transparent;
  color: white;
}

.p-lg {
  font-size: 1.4rem;
}

.bold {
  font-weight: 500;
}

.light {
  font-weight: 300;
}

.container-full {
  max-width: 100%;
}

.full-width {
  width: 100%;
}

.align-center {
  align-items: center;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.small-print {
  font-size: 0.8rem;
}

.filson-l {
  font-family: filson-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.filson-m {
  font-family: roc-grotesk, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.filson-b {
  font-family: roc-grotesk, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.jeff {
  font-family: jeff-script, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.container,
.container-lg {
  margin-left: auto;
  margin-right: auto;
}

.container {
  padding-left: 50px;
  padding-right: 50px;
}

.container-lg {
  padding-left: 15px;
  padding-right: 15px;
}

h1 {
  font-size: 70px;
  line-height: 1;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 50px;
  line-height: 1;
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3 {
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.mg-t-lg {
  margin-top: 100px;
}

.pd-t-lg {
  padding-top: 100px;
}

.pd-t-xl {
  padding-top: 125px;
}

.mg-t-md {
  margin-top: 75px;
}

.mg-t-sm {
  margin-top: 50px;
}

.light {
  font-weight: 200;
}

.universal-quote {
  padding: 25vh 0;
  text-align: center;
}

.universal-quote .jeff {
  margin-bottom: 0;
  font-size: 30px;
  margin-top: 0;
}

.quote-content {
  width: 75%;
  margin: 0 auto;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1350px;
  }
  .container-lg {
    max-width: 1650px;
  }
}

@media screen and (max-width: 992px) {
  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 45px;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 45px;
  }
  h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 35px;
    line-height: 1.1;
  }
  h3 {
    font-size: 25px;
    line-height: 1.2;
  }
  h4 {
    font-size: 22px;
    line-height: 1.2;
  }
  .p-lg {
    font-size: 1.5rem;
  }
  body {
    font-size: 1.1rem;
  }
}
