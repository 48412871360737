.news-hero {
  padding-top: 50px;
  border-top: 2px solid #d0d8e0;
}

.news-hero h1 {
  margin-bottom: 40px;
}

.categories-options {
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.categories-options > button:not(:first-of-type) {
  margin-left: 25px;
}

.categories-options button {
  border: 2px solid rgba(68, 68, 68, 0.4);
  background: transparent;
  padding: 8px 10px 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  color: #0f1b30;
}

.categories-options button.active {
  background: rgba(203, 229, 254, 0.4);
}

#news-items {
  margin-bottom: 100px;
}

.news-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px 25px;
}

.news-items > .news-item:first-of-type {
  grid-column-start: 1;
  grid-column-end: 4;
  position: relative;
}

/* .news-items > .news-item .image > img {
    height: auto;
} */

.news-items > .news-item:first-of-type h2 {
  font-size: 54px;
  color: white;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.news-items > .news-item:first-of-type p {
  color: white;
  font-size: 25px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 0;
}

.news-items > .news-item:first-of-type .details {
  font-size: 54px;
  position: absolute;
  top: 50%;
  left: 100px;
  right: 100px;
  transform: translateY(-50%);
  z-index: 2;
}

.news-items > .news-item:first-of-type .image {
  padding-bottom: 32.37%;
  margin-bottom: 0;
  position: relative;
}

.news-items > .news-item:first-of-type .image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.news-item h2 {
  font-size: 36px;
  font-weight: 400;
}

.news-item h2,
.news-item p {
  color: #0f1b30;
}

.news-item .image img {
  /* height: 600px; */
  object-fit: cover;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.news-item .image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 1rem;
}

.news-item .image .image-cat {
  position: absolute;
  top: 25px;
  right: 25px;
  padding: 8px 10px 5px 10px;
  border-radius: 20px;
  background: white;
  line-height: 1;
  font-size: 15px;
  font-weight: 400;
  color: #0f1b30;
  z-index: 2;
}

.news-item p {
  font-weight: 400;
}

@media screen and (max-width: 1200px) {
  .news-items > .news-item:first-of-type h2 {
    font-size: 45px;
  }
  .single-post-inner h1 {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .news-items > .news-item:first-of-type h2 {
    font-size: 36px;
  }
  .news-items > .news-item:first-of-type .details {
    left: 50px;
    right: 50px;
  }
  .news-items {
    grid-template-columns: 1fr 1fr;
  }
  .news-items > .news-item:first-of-type {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .news-items > .news-item:first-of-type .image {
    padding-bottom: 50%;
  }
}

@media screen and (max-width: 767px) {
  .categories-options > button:not(:first-of-type) {
    margin: 0;
  }
  .categories-options {
    flex-wrap: wrap;
    gap: 25px;
    justify-content: space-evenly;
  }
  .news-items {
    grid-template-columns: 1fr;
  }
  .news-items > .news-item:first-of-type {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .news-items > .news-item:first-of-type .image {
    padding-bottom: 100%;
    margin-bottom: 1rem;
  }
  .news-items > .news-item:first-of-type .details {
    position: initial;
    transform: none;
  }
  .news-items > .news-item:first-of-type h2,
  .news-items > .news-item:first-of-type p {
    color: #0f1b30;
    text-shadow: none;
  }
  .news-item h2,
  .news-items > .news-item:first-of-type h2 {
    font-size: 30px;
  }
  .news-items > .news-item:first-of-type p {
    font-size: 1.2rem;
  }
  .single-post-inner .single-post {
    display: block;
  }
  .single-post-inner .single-post .single-post-links {
    max-width: 100%;
    min-width: 100%;
    padding-right: 0;
    margin-bottom: 100px;
  }
}
