.spinner-loading {
  width: 50px;
  height: 50px;
  margin: 20px auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0f1b30;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
