.err-404,
#err-404 {
  height: 100vh;
  position: relative;
}

.err-404 .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  color: white;
  z-index: 2;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.45);
}

#err-404 .homer {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top left;
  position: absolute;
}

/* #err-404 .homer.gif {
  display: none;
} */

@media screen and (max-width: 1200px) {
  /* #err-404 .homer.gif {
    display: block;
  }
  #err-404 .homer.video {
    display: block;
  } */
}

@media screen and (max-width: 992px) {
  .err-404 .text {
    left: 0;
  }
  #err-404 .homer {
    object-position: top center;
  }
}

@media screen and (max-width: 576px) {
  #err-404 .homer {
    object-position: top center;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.45);
  }
}
