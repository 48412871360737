.projects-hero h1 {
  white-space: normal;
}

.image-service {
  position: relative;
  padding-bottom: 100%;
  border-radius: 8px;
  height: 0;
}

.image-service .project-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  object-fit: cover;
}

.image-service p {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white !important;
  background-color: #0f1b30;
  padding: 10px 20px;
  border-radius: 30px;
  line-height: 1;
}

.single-project p,
.single-project h2 {
  color: #0f1b30;
  font-weight: 400;
}

.single-project h2 {
  margin-top: 15px;
}

.project-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px 25px;
}
