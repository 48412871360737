#single-post {
  margin-top: 50px;
}

.single-post-hero {
  max-width: 100%;
}

.single-post-inner h1 {
  width: 50%;
  text-align: left;
  margin-bottom: 0px;
}

.single-post-inner .cat {
  background: #cbe5ff;
  padding: 8px 15px 5px 15px;
  border-radius: 20px;
  width: fit-content;
  font-weight: 400;
}

.single-post-inner .date-read {
  float: right;
}

.single-post-inner .date {
  margin-bottom: 0;
}

.single-post-hero img {
  border-radius: 10px;
  width: 100%;
}

.single-post-content > img {
  border-radius: 10px;
  margin-top: 50px;
  width: 100%;
}

.single-post-content h2 {
  font-size: 44px;
  padding-top: 50px;
}

.single-post-inner .single-post {
  display: flex;
}
.single-post-inner .single-post .single-post-links {
  padding-right: 100px;
  position: relative;
  margin-bottom: 1rem;
  margin-top: 50px;
  max-width: 45%;
  min-width: 25%;
}

.single-post-inner .single-post .single-post-links > div {
  position: sticky;
  top: 50px;
}

.single-post-inner .single-post .single-post-links a {
  display: block;
  margin-bottom: 1rem;
  color: #0f1b30;
  transition: 0.3s ease-in-out;
  line-height: 1.2;
}

.single-post-inner .single-post .single-post-links a.viewed {
  -webkit-text-stroke: 1px #0f1b30;
  transition: 0.3s ease-in-out;
}

.single-post-intro {
  padding-top: 50px;
}

.single-post-main img {
  border-radius: 10px;
}
